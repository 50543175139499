:root {

  --input-color: #2d3538;
  --input-border: #CDD9ED;
  --input-background: #fff;
  --input-placeholder: #8395a7;

  --input-border-focus: #2d3436;

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #95afc080;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus:#636e72;

}

.form-field {
  display: block;
  width: 100%;
  padding: 4px 8px;
  font-weight: 600;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border .3s ease;
  &::placeholder {
      color: var(--input-placeholder);
  }
  &:focus {
      outline: none;
      border-color: var(--input-border-focus);
  }
}

.form-group {
  position: relative;
  display: flex;
  width: 100%;
  margin: 5px 0;

  & > label,
  .form-field {
      white-space: nowrap;
      display: block;
      &:not(:first-child):not(:last-child) {
          border-radius: 0;
      }
      &:first-child {
          border-radius: 6px 0 0 6px;
      }
      &:last-child {
          border-radius: 0 6px 6px 0;
      }
      &:not(:first-child) {
          margin-left: -1px;
      }
  }
  .form-field {
      position: relative;
      z-index: 1;
      flex: 1 1 auto;
      width: 1%;
      margin-top: 0;
      margin-bottom: 0;
  }
  & > label {
      text-align: center;
      padding: 8px 12px;
      width: 40%;
      font-weight: 600;
      color: var(--group-color);
      background: var(--group-background);
      border: 1px solid var(--group-border);
      transition: background .3s ease, border .3s ease, color .3s ease;
  }
  &:focus-within {
      & > label {
          color: var(--group-color-focus);
          background: var(--group-background-focus);
          border-color: var(--group-border-focus);
      }
  }
}

.error-message {
    display: block;
    color: red;
    text-shadow: 0 0 3px #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    margin: 0 0 8px;
}