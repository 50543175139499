body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    overflow: hidden;
    background: #ecf0f1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0
    }
}